/* App.css */

.App {
  text-align: center;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.App-header {
  color: #333;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.main-content {
  flex: 1;
  padding-top: 90px; /* Adjusted for additional Navbar spacing */
  margin-right: 350px; /* Default spacing when sidebar is visible on large screens */
}

/* Removed any .posts styles from App.css to avoid overriding HomePage.css */

.app-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f7f9;
  color: #333;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.Sidebar {
  width: 350px;
  height: calc(100vh - 90px);
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 90px;
  bottom: 0;
}

input:not(.search-input)::placeholder,
textarea::placeholder {
  color: #777777;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

/* ------------------- Media Queries ------------------- */

/* For very small screens (up to 480px) */
@media (max-width: 480px) {
  .App-header {
    padding: 10px;
  }

  .app-footer {
    padding: 10px 0;
  }

  /* No .posts styles here to ensure no conflict */
}

/* Up to 768px wide: sidebar and main content adjustments */
@media (max-width: 768px) {
  .posts,
  .Sidebar {
    margin-left: 0;
    max-width: 100%;
    width: 100%;
  }

  .Sidebar {
    width: 80%;
    height: auto;
    position: static;
    top: auto;
  }

  .App {
    padding-bottom: 2rem;
  }

  .main-content {
    margin-right: 0; /* On smaller screens, remove reserved space */
  }

  /* No .posts styles here to ensure no conflict */
}

/* Between 820px and 1024px: no sidebar, so let content expand fully */
@media (min-width: 820px) and (max-width: 1024px) {
  .main-content {
    margin-right: 0; /* Remove the space reserved for the sidebar */
    padding-top: 120px; /* Adjust top padding if needed */
  }

  .posts {
    max-width: 100%; /* Allow posts to use full width if .posts is used */
  }
  
  /* If the Sidebar is hidden by design, ensure it's not displayed */
  .Sidebar {
    display: none;
  }
}

/* Adjustments for larger screens */
@media (min-width: 1264px) {
  #navbar .navbar-item {
    padding: 5px 10px !important;
    font-size: 1.1rem !important;
    border-radius: 12px !important;
  }

  #navbar .navbar-icon-bio,
  #navbar .navbar-icon-newposts,
  #navbar .navbar-icon-explore {
    width: 32px !important;
    height: 32px !important;
    font-size: 22px !important;
  }

  #navbar
    .navbar-icon:not(.navbar-icon-bio):not(.navbar-icon-newposts):not(.navbar-icon-explore) {
    width: 16px !important;
    height: 16px !important;
  }
}

@media (min-width: 1300px) {
  #navbar .navbar-item {
    padding: 5px 10px !important;
    font-size: 1.1rem !important;
    border-radius: 12px !important;
  }

  #navbar .navbar-icon-bio,
  #navbar .navbar-icon-newposts,
  #navbar .navbar-icon-explore {
    width: 32px !important;
    height: 32px !important;
    font-size: 22px !important;
  }

  #navbar
    .navbar-icon:not(.navbar-icon-bio):not(.navbar-icon-newposts):not(.navbar-icon-explore) {
    width: 16px !important;
    height: 16px !important;
  }
}

/* Additional spacing tweaks */
@media screen and (max-width: 1180px) {
  .main-content {
    padding-top: 110px;
  }
}

@media screen and (max-width: 1024px) {
  .main-content {
    padding-top: 120px;
  }
}

@media screen and (max-width: 768px) {
  .main-content {
    padding-top: 130px;
  }
}

@media (max-width: 1180px) {
  .main-content {
    margin-right: 0;
  }
}

/* Add this to your App.css file */
.MuiCardMedia-root {
  border: none !important;
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  outline: none !important;
}