/* HomePage.css */

/* Main posts container on the homepage */
.posts {
  /* Removed/zeroed out the old 'padding-top: 50px;' */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

/* Post container: used to center each post card */
.post-container {
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 15px;
  padding: 0;
  box-sizing: border-box;
}

/* Ensures the post itself is fully responsive */
.post-container .post {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-container .post img,
.post-container .post video {
  width: 100%;
  height: auto;
}

/* Basic layout container for homepage */
.home-page-container {
  min-height: 100vh;
  position: relative;
  padding-bottom: 80px;
}

/* Footer at bottom of home page */
.home-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #f3f3f3;
  text-align: center;
  padding: 20px 0;
}

.footer-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

/* ------------------- Media Queries ------------------- */

/* Up to 768px */
@media (max-width: 768px) {
  .posts {
    /* Removed the old 'padding-top: 40px;' */
    margin-left: 0;
    width: 100%;
  }

  /* CHANGED this block so the post-container no longer has max-width: 400px */
  .post-container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto 15px;
    /* If you want zero left/right padding, uncomment or adjust the next line: */
    /* padding: 0 10px; */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: none;
  }

  .post-container .post {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }

  .home-footer {
    padding: 15px 0;
  }
}

/* Up to 480px */
@media (max-width: 480px) {
  .posts {
    /* Removed the old 'padding-top: 40px;' */
  }

  .home-footer {
    padding: 10px 0;
  }

  .footer-link {
    font-size: 14px;
  }
}

/* Between 912px and 1024px */
@media (min-width: 912px) and (max-width: 1024px) {
  .posts {
    width: 90%;
    margin: 50px auto 0 auto;
  }

  .post-container {
    width: 100%;
    padding: 0 20px;
  }
}

@media (max-width: 375px) {
  .posts {
    /* Removed old margin-top: 40px; */
  }
}

@media (max-width: 414px) {
  .posts {
    /* Removed old margin-top: 40px; */
  }
}

@media (max-width: 430px) {
  .posts {
    /* Removed old margin-top: 40px; */
  }
}

@media (min-width: 540px) and (max-width: 720px) and (orientation: portrait),
       (min-width: 720px) and (max-width: 1114px) and (orientation: landscape) {
  .posts {
    /* Removed old margin-top: 120px; */
  }
}

@media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait),
       (min-width: 1366px) and (max-width: 1024px) and (orientation: landscape) {
  .posts {
    width: 90%;
    /* Removed old margin-top: 50px; */
  }

  .post-container {
    width: 100%;
    padding: 0 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait),
       only screen and (min-device-width: 1024px) and (max-device-width: 768px) and (orientation: landscape) {
  .posts {
    /* Removed old margin-top: 100px; */
  }
}

/* iPhone SE */
@media (max-width: 375px) and (max-height: 667px) {
  .posts {
    /* Removed old margin-top: 60px; */
  }
}

/* iPhone XR */
@media (max-width: 414px) and (max-height: 896px) {
  .posts {
    /* Removed old margin-top: 60px; */
  }
}

/* iPhone 12 Pro */
@media (max-width: 390px) and (max-height: 844px) {
  .posts {
    /* Removed old margin-top: 60px; */
  }
}

/* iPhone 14 Pro Max */
@media (max-width: 430px) and (max-height: 932px) {
  .posts {
    /* Removed old margin-top: 60px; */
  }
}

/* Pixel 7 */
@media (max-width: 393px) and (max-height: 851px) {
  .posts {
    /* Removed old margin-top: 60px; */
  }
}

/* Samsung Galaxy S8+ */
@media (max-width: 360px) and (max-height: 740px) {
  .posts {
    /* Removed old margin-top: 60px; */
  }
}

/* Samsung Galaxy S20 Ultra */
@media (max-width: 412px) and (max-height: 915px) {
  .posts {
    /* Removed old margin-top: 60px; */
  }
}

/* Galaxy Z Fold 5 */
@media (max-width: 816px) and (max-height: 2208px) {
  .posts {
    /* Removed old margin-top: 60px; */
  }
}

/* Samsung Galaxy A51/71 */
@media (max-width: 412px) and (max-height: 914px) {
  .posts {
    /* Removed old margin-top: 60px; */
  }
}

/* Up to 1180px */
@media (max-width: 1180px) {
  .home-page-container {
    padding-bottom: 60px;
  }

  .posts {
    /* Removed old 'padding-top: 30px;' and 'margin-top: 30px;' */
  }
}
@media (max-width: 1180px) {
  .post-container.post {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .post-container.post img,
  .post-container.post video {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1180px) {
  #navbar.search-input {
    margin-bottom: 20px;
  }

  .posts {
    /* Removed old 'margin-top: 30px;' */
  }

  .home-page-container {
    padding-bottom: 60px;
  }
}

/* Remove or comment out lines that previously added 5% left/right padding */
@media (max-width: 1180px) {
  .post-container {
    /* width: 90%;  <-- REMOVED or commented out */
    width: 100%;
    margin: 0 auto 20px auto;
    display: flex;
    justify-content: center;
  }
  
  .posts {
    margin: 0 auto;
    padding: 0 10px;
  }
}

@media (max-width: 1180px) {
  .posts {
    /* Removed old 'margin-top: 40px !important;' and 'padding-top: 100px !important;' */
  }
}

/* ----------------- Uniform Width for NewPosts ----------------- */
.post-container .post {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

@media (max-width: 1180px) {
  .post-container .post {
    max-width: 100%;
  }
}

/* Additional responsive adjustments */
@media (max-width: 768px) {
  .post-container .post {
    max-width: 100%;
    /* If you prefer zero padding at phone sizes, comment the next line out */
    /* padding: 0 10px; */
  }
}

@media (max-width: 480px) {
  .post-container .post {
    max-width: 100%;
    /* Remove this if you want zero LR padding on very small screens */
    /* padding: 0 5px; */
  }
}

/* Ensure consistent spacing between posts */
.post-container {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .post-container {
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .post-container {
    margin-bottom: 15px;
  }
}

/* Adjust post title font size for better readability */
.post .post-title {
  font-size: 24px;
}

@media (max-width: 768px) {
  .post .post-title {
    font-size: 22px;
  }
}

@media (max-width: 480px) {
  .post .post-title {
    font-size: 20px;
  }
}

/* Improve post metadata spacing */
.post .post-metadata {
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .post .post-metadata {
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .post .post-metadata {
    margin-bottom: 8px;
  }
}

/* Adjust post footer spacing */
.post .post-footer {
  margin-top: 16px;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .post .post-footer {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

@media (max-width: 480px) {
  .post .post-footer {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

/* Ensure consistent post image sizing */
.post .post-image {
  max-width: 100%;
  height: auto;
}

/* Improve post content spacing */
.post .post-content {
  margin-top: 12px;
}

@media (max-width: 768px) {
  .post .post-content {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .post .post-content {
    margin-top: 8px;
  }
}

/* Adjust post details font size */
.post .post-details {
  font-size: 14px;
}

@media (max-width: 768px) {
  .post .post-details {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .post .post-details {
    font-size: 12px;
  }
}
