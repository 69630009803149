/* Trending.css */

.posts {
  /* Removed the old 'padding-top: 70px;' */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .posts {
    width: calc(100% - 220px);
    margin-left: 200px;
    /* Removed margin-top: 40px; */
  }
}

@media (max-width: 768px) {
  .posts {
    /* Removed padding-top: 40px; */
    margin-left: 0;
    width: 100%;
  }
}

/* Adjust spacing between boxes */
@media (max-width: 1180px) {
  .posts {
    /* Removed margin-top: 20px; and padding-top: 80px; */
  }
}
